import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import { isUserLoggedIn } from './Base';

export default class Home extends Component {
  
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLoaded: false,
            items: [],
            showNav: false  
        }; 
    }
    
    componentDidMount() {
        if(!isUserLoggedIn()) {
            document.location = "/";
            //alert("NO!");
        }
    }
    componentWillUnmount() {}

    render() {

        return (
            <div id="home_box" style={{ display: (isUserLoggedIn() ? 'block' : 'none') }}>
                <h2>Your App</h2>
                <ul>
                    <li>
                        <em>Manage App &amp; Account</em>
                        <ul>
                            <li><Link to="/manage/account">Account Settings</Link></li>
                            <li><Link to="/manage/app">App Settings</Link></li>
                            <li><Link to="/manage/contest">Contest Settings</Link></li>
                            <li><Link to="/manage/home_screen">Home Screen Settings</Link></li>
                        </ul>
                    </li>
                </ul>
                <ul>
                    <li><Link to="/files">Manage Files (Non-video files)</Link></li>
                </ul>
                <ul>
                    <li><Link to="/gallery">Manage Gallery</Link></li>
                </ul>
                <ul>
                    <li><Link to="/locations">Manage Locations</Link></li>
                </ul>
                <ul>
                    <li><Link to="/coupons">Coupons</Link></li>
                </ul>
                <ul>
                    <li><Link to="/sendMessage">Send Message</Link></li>
                </ul>
                <ul>
                    <li><Link to="/contestEntries">Contest Entries</Link></li>
                </ul>
                <ul>
                    <li><Link to="/qrcodes">QR Code(s)</Link></li>
                </ul>
                
                <h2>Videos</h2>
                <ul>
                    <li><Link to="/videos">Manage Videos</Link></li>
                </ul>
            </div>
          );

    }

}