import Nubitt, { getAPIHeaders, getApiURL } from '../Base';

export default class EditVideo extends Nubitt {

    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLoaded: false,
            items: [],
            ads: [],
            showNav: false,
            fields: {
                video_name: '',
                video_description: '',
                scheduled_release: '',
                video_meta: '',
                selected_ad: null
            },
            video: undefined
        };
        this.handleOnChange = this.handleOnChange.bind(this); 
        this.handleOnSubmit = this.handleOnSubmit.bind(this);
    }

    getVideoID() {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        return urlParams.get('id');
    }

    componentDidMount() {
        document.title = "Edit Video : Nubitt Producers";
        this.loadData(this.getVideoID());
        this.loadAds();
    }

    loadAds() {
        fetch(getApiURL() + "/videos/all", {
            headers: getAPIHeaders()
        })
        .then(res => res.json())
        .then(
            (result) => {
                this.setState({
                    isLoaded: true,
                    ads: result.videos
                });
            },
            (err) => {
                this.setState({
                    ads: [],
                    isLoaded: true,
                    error: err
                });
            }
        )
    }

    loadData(videoID) {

        fetch(getApiURL() + "/video/" + videoID, {
            headers: getAPIHeaders()
        })
        .then(res => res.json())
        .then(
            (result) => {
                this.setState({
                    isLoaded: true,
                    items: result.videos,
                    fields: {
                        video_name: result.videos.video_name,
                        video_description: result.videos.video_description,
                        selected_ad: result.videos.video_ad_id
                    }
                });
            },
            (err) => {
                this.setState({
                    items: [],
                    isLoaded: true,
                    error: err
                });
            }
        )
    }

    clearAllFields() {
        for (let field in this.state.fields) {
            this.state.fields[field] = '';
        }
        this.state.video = undefined;
    }

    checkFields() {
        for (let field in this.state.fields) {
            if (this.state.fields[field].length === 0) {
                if (field === 'scheduled_release' || field === 'video_meta') {
                    continue
                }
                return false
            }
        }
        return true
    }

    handleOnChange(e) {
        const stateData = this.state;
        stateData.fields[e.target.name] = e.target.value
        this.setState({stateData});
    }

    handleOnSubmit(e) {
        e.preventDefault();  
        console.log(e);
        console.log(this.state.fields);

        fetch(getApiURL() + "/video/" + this.getVideoID(), {
            method: 'PUT',
            headers: getAPIHeaders(),
            body: JSON.stringify({
                fields: this.state.fields
            })
        })
        .then(
            (result) => {
                console.log(result);
                this.clearAllFields();
                document.location = '/videos';
            }
        )

    }

    render() {

        const { error, isLoaded, items, ads } = this.state;
        const isFormEnabled = true;

        console.log(this.state);

        if (error) {
            return <div><br />{error}</div>;
        } else if (!isLoaded) {
            console.log("Loading....");
            return <div>Loading...</div>;
        } else if (items) {
            console.log("Got Video!");
            console.log(items);
            return (<div>

                <h1>Edit Video: {this.state.fields.video_name}</h1>

                <div className="white-bg"> 
	
                    <form id="add_video" onSubmit={this.handleOnSubmit}>

                        <div className="form_item">
                            <input onChange={this.handleOnChange} value={this.state.fields.video_name} type="text" name="video_name" placeholder="Video Title" size="40" />
                        </div>

                        <div className="form_item">
                            <input onChange={this.handleOnChange} value={this.state.fields.video_description} type="text" name="video_description"  placeholder="Video Description" size="40" />
                        </div>

                        <p style={{display: 'inline-block'}}>
                            <span style={{display: 'inline-block', width: '120px'}} className="lbl">Ad:</span><br />
                            <select name="selected_ad" onChange={this.handleOnChange} value={(this.state.fields.selected_ad == null) ? '' : this.state.fields.selected_ad}>
                                <option defaultValue="-1">Choose an Ad...</option>
                                <option defaultValue="no">...Do Not Show Ad...</option>
                                {ads.map(item => (
                                    <option key={'ad_option_' + item.video_id} value={item.video_id}>{item.video_name}</option>
                                ))}
                            </select>
                        </p>

                        <div className="form_submit">
                            <input disabled={!isFormEnabled} type="submit" name="submit" value="Update Video" />
                        </div>

                    </form>

                </div>
        
            </div>);
        } else if (!items) {
            return (
                <div></div>
            );
        }

    }

}