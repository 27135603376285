import Nubitt, { getApiURL } from './Base';

export default class Login extends Nubitt {

    constructor(props) {
        super(props);
        console.log(props);
        this.state = {
            error: null,
            data: {},
            username: '',
            password: '',
            showLogin: false
        };
        this.handleUsernameChange = this.handleUsernameChange.bind(this);
        this.handlePassChange = this.handlePassChange.bind(this);
        this.doLogin = this.doLogin.bind(this);
        this.onFailed = this.onFailed.bind(this);
        this.onSuccess = this.onSuccess.bind(this);
    }

    handleUsernameChange(event) {
        this.setState({username: event.target.value});
    }

    handlePassChange(event) {
        this.setState({password: event.target.value});
    }

    onSuccess(response) {
        console.log(response);
        //response.json().then(body => {
        //	storeLoginData(body);
        //	document.location = "/";
        //});
    }

    onFailed(error) {
        console.log(error);
        //global.showAlertNoTitle(error);
    }

    componentDidMount() {
        document.title = "Sign In : Admin - Nubitt Dashboard";
        document.body.classList.add('login_view');

        // Check for auto-login
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);

        if (urlParams.get('login_as_user')) {

            console.log('Auto-login request found, try it...');

            localStorage.clear();

            let user = urlParams.get('login_as_user');
            let hash = urlParams.get('login_hash');

            fetch(getApiURL() + "/user/login", {
                method: 'post',
                body: "{\"user_id\" : \"" + user + "\", \"hash\" : \"" + hash + "\"}",
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Client': 'producer-ui'
                },
            })
            .then(response => {
                if (!response.ok) { throw response }
                console.log(response);
                return response.json()
            })
            .then(
                (result) => {
                    
                    console.log(result);
    
                    if (result.response === 'USER_LOGGED_IN') {
                        
                        this.setState({
                            isLoggedIn: true,
                            data: result
                        });
    
                        // Store user
                        localStorage.setItem("user_data", JSON.stringify(result.user));
                        localStorage.setItem("user_token", result.token);
                        document.location = '/home';
    
                    }
    
                },
                (error) => {
                    console.log("error logging in");
                    console.log(error);
                }
            )

        } else {
            this.setState({showLogin: true});
        }

    }

    componentWillUnmount() {
        document.body.classList.remove('login_view');
    }

    doLogin = function(e) {
        e.preventDefault();

        var username = this.state.username;
        var password = this.state.password;

        if (username.length === 0 || password.length === 0) {
            alert("Username and password are required.");
            return;
        }

        fetch(getApiURL() + "/user/login", {
            method: 'post',
            body: "{\"username\" : \"" + username + "\", \"password\" : \"" + password + "\"}",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
        })
        .then(response => {
            if (!response.ok) { throw response }
            console.log(response);
            return response.json()
        })
        .then(
            (result) => {
                
                console.log(result);

                if (result.response === 'USER_LOGGED_IN') {
                    
                    this.setState({
                        isLoggedIn: true,
                        data: result
                    });

                    // Store user
                    localStorage.setItem("user_data", JSON.stringify(result.user));
                    localStorage.setItem("user_token", JSON.stringify(result.token));
                    document.location = '/home';

                }

            },
            (error) => {
                console.log("error logging in");
                console.log(error);
            }
        )

    }

    render() {

        const { showLogin } = this.state;

        return (
            <div style={{ display: (showLogin ? 'block' : 'none') }}>
                <div id="login_box">
                    <form onSubmit={this.doLogin} className="login">
                        <p><span>Username:</span><input 
                            type="username" 
                            name="username" 
                            placeholder="Enter Username" 
                            value={this.state.username} 
                            onChange={this.handleUsernameChange} /></p>
                        <p><span>Password:</span><input 
                            type="password" 
                            name="pass" 
                            placeholder="Enter Password"
                            value={this.state.password} 
                            onChange={this.handlePassChange} /></p>
                        <p><input name="submit" type="submit" value="Sign In" /></p>
                    </form>
                </div>
            </div>
          );
    }

}