import Nubitt, { getAPIHeaders, getApiURL, getAssetURL } from "../Base";
import FileUpload from "../components/FileUpload";

export default class Coupons extends Nubitt {

    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLoaded: false,
            items: [],
            showNav: false  
        };
    }

    componentDidMount() {
        document.title = "Coupons : Nubitt Producers";
        this.loadData();
    }

    loadData() {

        fetch(getApiURL() + "/coupons", {
            headers: getAPIHeaders()
        })
        .then(res => res.json())
        .then(
            (result) => {
                this.setState({
                    isLoaded: true,
                    items: result.coupons
                });
            },
            (err) => {
                this.setState({
                    items: [],
                    isLoaded: true,
                    error: err
                });
            }
        )
    }

    handleUploadComplete = (e) => {
        console.log("File has been uploaded");
        console.log(e);
        this.loadData();
    };

    deleteItem(e, id) {
        e.preventDefault();

        let confirmed = window.confirm("Are you sure you want to delete this coupon?");
        if (confirmed) {

            fetch(getApiURL() + "/coupons/" + id, {
                method: 'DELETE',
                headers: getAPIHeaders()
            })
            .then(
                (result) => {
                    this.loadData();
                }
            )

        }

    }

    render() {

        const { error, isLoaded, items } = this.state;

        console.log(items);

        let header = <h1 key="header">Manage Coupons</h1>
        let coupon_list = ''

        let form = (<div key="form-container">
            <div className="white-bg"> 
                <h3>Add New Coupon</h3>
                <FileUpload
                    uploadUrl={getApiURL() + '/coupons/new'}
                    onUploadComplete={this.handleUploadComplete}
                />
            </div>
        </div>)

        if (error) {
            return <div><br />{error}</div>;
        } else if (!isLoaded) {
            return <div>Loading...</div>;
        } else if (items) {

            coupon_list = (
                <div key="coupon-table">
                    <h3>Existing Coupons</h3>
                    <table className="table table-striped">
                        <thead>
                            <tr>
                                <th>Coupon Code</th>
                                <th>File</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {items.map(item => (
                                <tr key={'coupon_' + item.id}>
                                    <td>
                                        <small>{item.code}</small>
                                    </td>
                                    <td>
                                        <small><a target="_blank" rel="noreferrer" href={getAssetURL() + item.file_url}>[link]</a></small>
                                    </td>
                                    <td><a href="/" onClick={(e) => this.deleteItem(e, item.id)}><small>Delete</small></a></td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            );

            if (items.length === 0) {
                coupon_list = (
                    <p className="no-item-warning">
                        No existing coupons found.
                        <span>Add your first, using the form above.</span>
                    </p>
                )
            }

            return [header, form, coupon_list];

        } else if (!items || items.length === 0) {
            return [header, form, <div>No coupons found...</div>];
        }

    }

}