import Nubitt, { formatPhoneNumber, getAPIHeaders, getApiURL } from "../Base";

export default class Contest extends Nubitt {

    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLoaded: false,
            items: [],
            showNav: false  
        };
    }

    componentDidMount() {
        document.title = "Contest : Nubitt Producers";
        this.loadData();
    }

    loadData() {

        fetch(getApiURL() + "/contest", {
            headers: getAPIHeaders()
        })
        .then(res => res.json())
        .then(
            (result) => {
                this.setState({
                    isLoaded: true,
                    items: result.entries
                });
            },
            (err) => {
                this.setState({
                    items: [],
                    isLoaded: true,
                    error: err
                });
            }
        )
    }

    render() {

        const { error, isLoaded, items } = this.state;

        console.log(items);

        if (error) {
            return <div><br />{error}</div>;
        } else if (!isLoaded) {
            return <div>Loading...</div>;
        } else if (items && items.length > 0) {
            return (
                <div>
                    <h2>Contest Entries</h2>
                    <table className="table table-striped">
                        <thead>
                            <tr>
                                <th>User ID</th>
                                <th>First Name</th>
                                <th>Email</th>
                                <th>Phone</th>
                                <th>Date Entered</th>
                            </tr>
                        </thead>
                        <tbody>
                            {items.map(item => (
                                <tr key={'entry_' + item.id}>
                                    <td>{item.user_id}</td>
                                    <td>{item.first_name}</td>
                                    <td>{item.email}</td>
                                    <td>{formatPhoneNumber(item.phone_number)}</td>
                                    <td>{item.date_submitted}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
        
            );
        } else if (items.length === 0) {
            return (
                <p className="no-item-warning">
                    No contest entries found.
                </p>
            );
        }

    }

}