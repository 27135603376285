import React, { useState } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { getAPIHeaders, getApiURL } from '../Base';

const DraggableRow = ({ item, index, moveRow, onDrop }) => {
  const [, drag] = useDrag({
    type: 'ROW',
    item: { index },
  });

  const [, drop] = useDrop({
    accept: 'ROW',
    hover: (dragged) => {
      if (dragged.index !== index) {
        moveRow(dragged.index, index);
        dragged.index = index;
      }
    },
    drop: () => {
      onDrop(); // Call the onDrop function when the drop operation is complete
    },
  });

  const visibleOptions = [
    { label: 'Visible', value: 1 },
    { label: 'Hidden', value: 0 }
  ];

  // Initialize state for the selected value
  const [selectedValue, setSelectedValue] = useState(item.link_visible);

  const handleDelete = (e) => {
    if (window.confirm('Delete button?')) {
      fetch(getApiURL() + "/manage/home_screen/button/" + item.id, {
        method: 'DELETE',
        headers: getAPIHeaders(),
        body: ''
      })
      .then(
        (result) => {
            window.location.reload();
            console.log("Button deleted");
            console.log(result);
        }
      )
    }
  };
  
  const handleSelectChange = (e) => {
    const newValue = e.target.value;
    setSelectedValue(newValue);
    // Update item.link_visible
    item.link_visible = newValue;
    console.log(item);

    fetch(getApiURL() + "/manage/home_screen/toggle_visibility", {
      method: 'PATCH',
      headers: getAPIHeaders(),
      body: JSON.stringify({
          fields: item
      })
    })
    .then(
      (result) => {
          console.log("Data updated");
          console.log(result);
      }
    )

  };

  return (
    <tr ref={(node) => drag(drop(node))}>
      <td>{item.link_name}</td>
      <td>{item.link_type}</td>
      <td>{item.link_url}</td>
      <td>
        <select
          onChange={handleSelectChange}
          value={selectedValue}
          style={{ fontSize: '12px', padding: '0px' }}
          name="btn_type"
        >
          {visibleOptions.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      </td>
      <td>
        <small>
          <a href="/" onClick={(e) => {
            e.preventDefault();
            handleDelete();
          }}>
            Delete
          </a>
        </small>
      </td>
    </tr>
  );
};

export default DraggableRow;