import Nubitt, { getAPIHeaders, getApiURL } from "../../Base";
import ColorPicker from "../../components/ColorPicker";
import ManageHeaderView from "./ManageHeaderView";

export default class ManageApp extends Nubitt {

    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLoaded: false,
            settings: null,
            images: null,
            showNav: false,
            app_color: '',
            app_text_color: '',
            selected_app_logo_image: '',
            selected_header_image: '',
            selected_bg_image: '',
        };
        this.handleOnChange = this.handleOnChange.bind(this);
        this.handleOnSubmit = this.handleOnSubmit.bind(this);
    }

    componentDidMount() {
        document.title = "Manage App : Nubitt Producers";
        this.loadData();
    }

    handleSelectedAppLogoImageChange = (e) => {
        this.setState({ selected_app_logo_image: e.target.value });
    };

    handleSelectedHeaderImageChange = (e) => {
        this.setState({ selected_header_image: e.target.value });
    };

    handleSelectedBackgroundImageChange = (e) => {
        this.setState({ selected_bg_image: e.target.value });
    };

    loadData() {

        fetch(getApiURL() + "/manage/app", {
            headers: getAPIHeaders()
        })
        .then(res => res.json())
        .then(
            (result) => {
                this.setState({
                    isLoaded: true,
                    settings: result.settings,
                    images: result.images
                });
                this.setState({
                    app_color: result.settings.app_color,
                    app_text_color: result.settings.app_text_color
                });
                this.setState({
                    selected_app_logo_image: result.settings.app_logo,
                    selected_header_image: result.settings.app_banner,
                    selected_bg_image: result.settings.app_background
                });
            },
            (err) => {
                this.setState({
                    settings: null,
                    isLoaded: true,
                    error: err
                });
            }
        )
    }

    handleChangeAppColor = (color) => {
        console.log(color);
        this.setState({ app_color: color });
    };

    handleChangeAppTextColor = (color) => {
        this.setState({ app_text_color: color });
    };

    handleOnChange(e) {
        console.log(e);
        const stateData = this.state;
        stateData.settings[e.target.name] = e.target.value
        this.setState({stateData});
    }

    handleOnSubmit(e) {
        e.preventDefault();  
        console.log(e);

        fetch(getApiURL() + "/manage/app", {
            method: 'PATCH',
            headers: getAPIHeaders(),
            body: JSON.stringify({
                app_name: this.state.settings.app_name,
                one_signal_app_id: this.state.settings.one_signal_app_id,
                one_signal_api_key: this.state.settings.one_signal_api_key,
                producer_description: this.state.settings.producer_description,
                google_analytics_id: this.state.settings.google_analytics_id,
                app_color: this.state.app_color,
                app_text_color: this.state.app_text_color,
                app_logo: this.state.selected_app_logo_image,
                app_banner: this.state.selected_header_image,
                app_background: this.state.selected_bg_image
            })
        })
        .then(
            (result) => {
                console.log(result);
                //this.clearAllFields();
                //this.loadData();
            }
        )

    }

    render() {

        const { error, isLoaded, images, settings, app_color, app_text_color, selected_app_logo_image, selected_header_image, selected_bg_image } = this.state;

        console.log(settings);
        console.log("app_color: " + app_color);
        console.log("app_text_color: " + app_text_color);
        console.log(images);

        if (error) {
            return <div><br />{error}</div>;
        } else if (!isLoaded) {
            return <div>Loading...</div>;
        } else if (settings) {
            return(
                <div>
    
                    <ManageHeaderView />
    
                    <h2>App Settings</h2>
                    
                    <form id="update_app_settings" onSubmit={this.handleOnSubmit}>
    
                            <h3>App Info</h3>

                            <p>
                                <span style={{display: 'inline-block', width: '150px'}}>App Name</span>
                                <input 
                                    onChange={this.handleOnChange} 
                                    value={settings.app_name} 
                                    size="50" 
                                    type="text" 
                                    name="app_name" />
                            </p>
    
                            <p>
                                <span style={{display: 'inline-block', width: '150px'}}>Producer Desc.</span>
                                <input 
                                    onChange={this.handleOnChange} 
                                    value={settings.producer_description} 
                                    size="50" 
                                    type="text" 
                                    name="producer_description" />
                            </p>

                            <div>
                                <span style={{display: 'inline-block', width: '150px'}}>Theme Color</span>
                                <div style={{display: 'inline-block', marginBottom: '10px'}}><ColorPicker defaultColor={settings.app_color} onSave={this.handleChangeAppColor} /></div>
                            </div>
    
                            <div>
                                <span style={{display: 'inline-block', width: '150px'}}>Text Color</span>
                                <div style={{display: 'inline-block'}}><ColorPicker defaultColor={settings.app_text_color} onSave={this.handleChangeAppTextColor} /></div>
                            </div>

                            <h3>App Configuration</h3>

                            <p>
                                <span style={{display: 'inline-block', width: '150px'}}>OneSignal App ID</span>
                                <input 
                                    onChange={this.handleOnChange} 
                                    value={settings.one_signal_app_id} 
                                    size="50" 
                                    type="text" 
                                    name="one_signal_app_id" />
                            </p>

                            <p>
                                <span style={{display: 'inline-block', width: '150px'}}>OneSignal API Key</span>
                                <input 
                                    onChange={this.handleOnChange} 
                                    value={settings.one_signal_api_key} 
                                    size="50" 
                                    type="text" 
                                    name="one_signal_api_key" />
                            </p>
    
                            <p>
                                <span style={{display: 'inline-block', width: '150px'}}>Analytics ID</span>
                                <input
                                    onChange={this.handleOnChange} 
                                    value={settings.google_analytics_id}
                                    size="50" 
                                    type="text" 
                                    name="google_analytics_id"
                                    placeholder="Google Analytics Tracking ID (ex: UA-XXXXXXX-X)" />
                            </p>

                            <h3>App Graphics</h3>
    
                            <p>
                                <span style={{display: 'inline-block', width: '150px'}}>App Logo</span>
                                <select value={selected_app_logo_image} onChange={this.handleSelectedAppLogoImageChange} style={{marginLeft: -2  + 'px', padding: 10 + 'px'}}>
                                    {images.map(item => (
                                        <option key={'logo_' + item.id} value={item.file_url}>{item.file_name}</option>
                                    ))}
                                </select>
                            </p>
    
                            <p>
                                <span style={{display: 'inline-block', width: '150px'}}>Header Image</span>
                                <select value={selected_header_image} onChange={this.handleSelectedHeaderImageChange} style={{marginLeft: -2  + 'px', padding: 10 + 'px'}}>
                                    {images.map(item => (
                                        <option key={'header_' + item.id} value={item.file_url}>{item.file_name}</option>
                                    ))}
                                </select>
                            </p>

                            <p>
                                <span style={{display: 'inline-block', width: '150px'}}>Background Image</span>
                                <select value={selected_bg_image} onChange={this.handleSelectedBackgroundImageChange} style={{marginLeft: -2  + 'px', padding: 10 + 'px'}}>
                                    {images.map(item => (
                                        <option key={'bg_' + item.id} value={item.file_url}>{item.file_name}</option>
                                    ))}
                                </select>
                            </p>
                            
                            <p>
                                <input type="submit" name="submit" value="Update Settings" />
                            </p>
    
                    </form>
    
                </div>
            )
        }

    }

}