import Nubitt, { getAPIHeaders, getApiURL, getAssetURL } from "../Base";
import FileUpload from "../components/FileUpload";

export default class Files extends Nubitt {

    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLoaded: false,
            items: [],
            showNav: false  
        };
    }

    componentDidMount() {
        document.title = "Files : Nubitt Producers";
        this.loadData();
    }

    toggleEnabled = function(e, item) {

        item.file_active = (e.target.checked) ? 1 : 0;
        fetch(getApiURL() + "/files/toggle_enable/" + item.id, {
            method: 'POST',
            headers: getAPIHeaders(),
            body: JSON.stringify({
                enabled: item.file_active
            })
        })
    }

    loadData() {

        fetch(getApiURL() + "/files/all", {
            headers: getAPIHeaders()
        })
        .then(res => res.json())
        .then(
            (result) => {
                this.setState({
                    isLoaded: true,
                    items: result.files
                });
            },
            (err) => {
                this.setState({
                    items: [],
                    isLoaded: true,
                    error: err
                });
            }
        )
    }

    handleUploadComplete = (e) => {
        console.log("File has been uploaded");
        console.log(e);
        this.loadData();
    };

    deleteItem(e, id) {
        e.preventDefault();

        let confirmed = window.confirm("Are you sure you want to delete this file?");
        if (confirmed) {

            fetch(getApiURL() + "/file/" + id, {
                method: 'DELETE',
                headers: getAPIHeaders()
            })
            .then(
                (result) => {
                    this.loadData();
                }
            )

        }

    }

    render() {

        const { error, isLoaded, items } = this.state;

        console.log(items);

        if (error) {
            return <div><br />{error}</div>;
        } else if (!isLoaded) {
            return <div>Loading...</div>;
        } else if (items) {
            return (
                <div>
                    <h2>Manage Files</h2>

                    <h3>Add New File</h3>
                    <FileUpload
                        uploadUrl={getApiURL() + '/files/upload'}
                        onUploadComplete={this.handleUploadComplete}
                    />

                    { items.length > 0 ? (
                        <div>
                            <h3>Existing Files</h3>
                            <table className="table table-striped">
                                <thead>
                                    <tr>
                                        <th>File Name</th>
                                        <th>File Date</th>
                                        <th>Enabled?</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {items.map(item => (
                                        <tr key={'file_' + item.id}>
                                            <td>
                                                <small>{item.file_name}</small><br />
                                                <small><a target="_blank" rel="noreferrer" href={getAssetURL() + item.file_url}>[link]</a></small>
                                            </td>
                                            <td>{item.formatted_date_added}</td>
                                            <td><input onChange={event => this.toggleEnabled(event, item)} defaultChecked={item.file_active} className="show_file" type="checkbox" name={'file_enabled_' + item.id} /></td>
                                            <td><a href="/" onClick={(e) => this.deleteItem(e, item.id)}>Delete</a></td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    ) : (
                        <p className="no-item-warning">
                            No existing files found.
                            <span>Add your first, using the form above.</span>
                        </p>
                    )}

                </div>
        
            );
        } else if (!items) {
            return (
                <div>No files found...</div>
            );
        }

    }

}