import Nubitt, { getAPIHeaders, getApiURL, getAssetURL } from '../Base';

export default class QRCodes extends Nubitt {

    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLoaded: false,
            items: [],
            showNav: false,
            marketing_tag: ''
        };
        this.handleOnChange = this.handleOnChange.bind(this); 
        this.handleOnSubmit = this.handleOnSubmit.bind(this);
    }

    componentDidMount() {
        document.title = "QR Codes : Nubitt Producers";
        this.loadData();
    }

    handleOnChange(e) {
        this.setState({
            marketing_tag: e.target.value
        });
    }

    handleOnSubmit(e) {
        e.preventDefault();  
        console.log(e);

        fetch(getApiURL() + "/qrcodes/add", {
            method: 'POST',
            headers: getAPIHeaders(),
            body: JSON.stringify({
                marketing_tag: this.state.marketing_tag
            })
        })
        .then(res => res.json())
        .then(
            (result) => {

                this.setState({
                    marketing_tag: ''
                });

                if (result.code && result.code === 'CODE_IN_USE') {
                    alert(result.message);
                } else {
                    this.loadData();
                }

            }
        )

    }

    loadData() {

        fetch(getApiURL() + "/qrcodes", {
            headers: getAPIHeaders()
        })
        .then(res => res.json())
        .then(
            (result) => {
                this.setState({
                    isLoaded: true,
                    items: result.codes
                });
            },
            (err) => {
                this.setState({
                    items: [],
                    isLoaded: true,
                    error: err
                });
            }
        )
    }

    deleteItem(e, id) {
        e.preventDefault();

        let confirmed = window.confirm("Are you sure you want to delete this QR code? This action cannot be undone!");
        if (confirmed) {

            fetch(getApiURL() + "/qrcodes/" + id, {
                method: 'DELETE',
                headers: getAPIHeaders()
            })
            .then(
                (result) => {
                    this.loadData();
                }
            )

        }

    }

    render() {

        const { error, isLoaded, items } = this.state;
        const isFormEnabled = (this.state.marketing_tag.length === 0) ? false : true;
        
        console.log(items);

        let form = (<div key="form-container">
            <h1>QR Codes</h1>
            <div className="white-bg"> 
            
                <h2>Create QR Code</h2>
                <form id="add_qrcode" onSubmit={this.handleOnSubmit}>
                    <div className="form_item">
                        <input onChange={this.handleOnChange} value={this.state.marketing_tag} type="text" name="location_name" id="location_name" placeholder="QR Marketing Tag (required)" size="40" />
                    </div>
                    <div className="form_submit">
                        <input id="upload_btn" disabled={!isFormEnabled} type="submit" name="submit" value="Add New QR Code" />
                    </div>
                </form>
            </div>
        </div>)

        if (error) {
            return <div><br />{error}</div>;
        } else if (!isLoaded) {
            return <div>Loading...</div>;
        } else if (items) {

            let list = ""

            if (items.length > 0) {
                list = (
                    <div key="qr-table">
                        <h2>Existing QR Codes</h2>
                        <table className="table table-striped">
                            <thead>
                                <tr>
                                    <th>QR Code</th>
                                    <th>Tag</th>
                                    <th>Scans</th>
                                    <th>URL</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {items.map(item => (
                                    <tr key={'codes_' + item.id}>
                                        <td><img alt="" width="100" src={getAssetURL() + item.qr_image_path} /></td>
                                        <td>{item.qr_tag}</td>
                                        <td>{item.uses}</td>
                                        <td><small><a rel="noreferrer" target="_blank" href={item.qr_url}>{item.qr_url}</a></small></td>
                                        <td><a href="/" onClick={(e) => this.deleteItem(e, item.id)}><small>Delete</small></a></td>
                                    </tr> 
                                ))}
                            </tbody>
                        </table>
                    </div>
                )
            } else {
                list = (
                    <p className="no-item-warning">
                        No existing QR Codes found.
                        <span>Add your first, using the form above.</span>
                    </p>
                )
            }

            return [form, list];

        } else if (items.length === 0) {
            return (
                <div>No QR codes to show.</div>
            );
        }

    }

}