import { Component } from 'react';
import { getAPIHeaders } from '../Base';
export default class FileUpload extends Component {
    
    constructor(props) {
      super(props);
      this.state = {
        uploadedFile: null,
        uploadProgress: 0
      };
    }

    clearUpload(e) {
        e.target.value = null;
    }
  
    handleFileUpload = async (e) => {
      const file = e.target.files[0];
      this.setState({ uploadedFile: file });
  
      const formData = new FormData();
      formData.append('file', file);
  
      const { uploadUrl, onUploadComplete } = this.props;

      try {
        const response = await fetch(uploadUrl, {
          headers: getAPIHeaders(true),
          method: 'POST',
          body: formData,
          onProgress: (progressEvent) => {
            if (progressEvent.lengthComputable) {
              const percentCompleted = Math.round(
                (progressEvent.loaded / progressEvent.total) * 100
              );
              this.setState({ uploadProgress: percentCompleted });
            }
          },
        });
  
        if (!response.ok) {
          console.log(response);
          throw new Error(`HTTP Error! Status: ${response.status}`);
        }
  
        // Handle the response from the server as needed
        console.log('File uploaded successfully');

        this.clearUpload(e);

        if (typeof onUploadComplete === 'function') {
            onUploadComplete(this);
        }

      } catch (error) {
        // Handle errors
        console.error(error);
      }
    };

    render() {
        return(
            <div>
                <input type="file" onChange={this.handleFileUpload} />
                <div id="progress">
                    <div className="bar" style={{width: this.uploadProgress + '%'}}></div>
                </div>
            </div>
        )
    }

}