//import logo from './logo.svg';
import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import Header from './Header';
import Footer from './Footer';

import Home from './Home';
import Login from './Login';

// Pages
import Gallery from './views/Gallery'
import Locations from './views/Locations'
import Videos from './views/Videos'
import EditVideo from './views/EditVideo';
import Files from './views/Files'
import QRCodes from './views/QRCodes'
import Contest from './views/Contest'
import SendMessageView from './views/SendMessageView'
import Coupons from './views/Coupons'

// Management
import ManageAccount from './views/manage/ManageAccount'
import ManageApp from './views/manage/ManageApp'
import ManageContest from './views/manage/ManageContest'
import ManageHomeScreen from './views/manage/ManageHomeScreen'

function App() {
  return (
    <div>
      <Header />
      <Router>
        <Routes>
          
          <Route path="/" exact element={<Login />} />
          <Route path="/home" exact element={<Home />} />
          
          <Route path="/videos" exact element={<Videos />} />
          <Route path="/edit_video" exact element={<EditVideo />} />
          <Route path="/gallery" exact element={<Gallery />} />
          <Route path="/locations" exact element={<Locations />} />
          <Route path="/files" exact element={<Files />} />
          <Route path="/qrcodes" exact element={<QRCodes />} />
          <Route path="/contestEntries" exact element={<Contest />} />
          <Route path="/sendMessage" exact element={<SendMessageView />} />
          <Route path='/coupons' exact element={<Coupons />} />

          <Route path="/manage/account" exact element={<ManageAccount />} />
          <Route path="/manage/app" exact element={<ManageApp />} />
          <Route path="/manage/contest" exact element={<ManageContest />} />
          <Route path="/manage/home_screen" exact element={<ManageHomeScreen />} />

        </Routes>
      </Router>
      <Footer />
    </div>
  );
}

export default App;

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();