import React, { Component } from 'react'
import { isUserLoggedIn, user } from './Base';

export default class Header extends Component {

    constructor(props) {
        super(props);
        this.state = {
            error: null
        };
        this.doLogout = this.doLogout.bind(this);
    }

    doLogout = function(e) {
        e.preventDefault();
        localStorage.clear();
        document.location = "/";
    }

    render() {
        return (
            <div id="header">
                <strong><a href="/home">Nubitt Producers</a></strong>
                <div style={{ display: (isUserLoggedIn() ? 'block' : 'none') }}>Hello, {user().login_user} <small onClick={this.doLogout} className="sign_out_link">(<a href="/">Sign Out</a>)</small></div>
                <hr />
            </div>
          );
    }

}