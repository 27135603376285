import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { isUserLoggedIn } from './Base';


if (window.location.pathname === "/") {
  if (isUserLoggedIn()) {
    // Check for auto-login
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    if (!urlParams.get('login_as_user')) {
      console.log("Producer is logged in...");
      document.location = "/home";
    }
  }
} else {
  if (window.location.pathname !== "/") {
    if (!isUserLoggedIn()) {
      console.log("Producer is NOT logged in...");
      document.location = "/";
    } else {
      console.log("Producer is logged in...");
    }
  }
}

console.log(window.location.pathname);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <App />
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
