import Nubitt, { getAPIHeaders, getApiURL } from '../Base';

export default class Videos extends Nubitt {

    constructor(props) {
        super(props);
        
        this.state = {
            error: null,
            isLoaded: false,
            items: [],
            showNav: false,
            fields: {
                video_name: '',
                video_description: '',
                scheduled_release: '',
                video_meta: ''
            },
            video: undefined
        };
        
        this.handleFileChange = this.handleFileChange.bind(this);
        this.handleOnChange = this.handleOnChange.bind(this);
        this.handleOnSubmit = this.handleOnSubmit.bind(this);

    }

    componentDidMount() {
        document.title = "Videos : Nubitt Producers";
        this.loadData();
    }

    loadData() {

        fetch(getApiURL() + "/videos/all", {
            headers: getAPIHeaders()
        })
        .then(res => res.json())
        .then(
            (result) => {
                this.setState({
                    isLoaded: true,
                    items: result.videos
                });
            },
            (err) => {
                this.setState({
                    items: [],
                    isLoaded: true,
                    error: err
                });
            }
        )
    }

    clearAllFields() {
        for (let field in this.state.fields) {
            this.state.fields[field] = '';
        }
        this.state.video = undefined;
    }

    checkFields() {
        for (let field in this.state.fields) {
            if (this.state.fields[field].length === 0) {
                if (field === 'scheduled_release' || field === 'video_meta') {
                    continue
                }
                return false
            }
        }
        return true
    }

    handleFileChange(event) {
        this.setState({
            video: event.target.files[0]
        });
    }

    handleOnChange(e) {
        const stateData = this.state;
        stateData.fields[e.target.name] = e.target.value
        this.setState({stateData});
    }

    handleOnSubmit(e) {
        e.preventDefault();  
        console.log(e);
        
        console.log(this.state.fields);
        
        console.log("Video:");
        console.log(this.state.video);

        const formData = new FormData();

        // Video Data
        formData.append('video', this.state.video);
        formData.append('video_name', this.state.fields.video_name);
        formData.append('video_description', this.state.fields.video_description);
        formData.append('scheduled_release', this.state.fields.scheduled_release);
        formData.append('video_meta', this.state.fields.video_meta);

        fetch(getApiURL() + "/videos/add", {
            method: 'POST',
            headers: getAPIHeaders(true),
            body: formData
        })
        .then(
            (result) => {
                console.log(result);
                this.clearAllFields();
                this.loadData();
            }
        )

    }

    deleteItem(e, id) {
        e.preventDefault();

        let confirmed = window.confirm("Are you sure you want to delete this video?");
        if (confirmed) {

            fetch(getApiURL() + "/video/" + id, {
                method: 'DELETE',
                headers: getAPIHeaders()
            })
            .then(
                (result) => {
                    this.loadData();
                }
            )

        }

    }

    render() {

        const { error, isLoaded, items } = this.state;
        const isVideoUploadEnabled = this.checkFields();
        const isFormEnabled = true;

        if (error) {
            return <div><br />{error}</div>;
        } else if (!isLoaded) {
            return <div>Loading...</div>;
        } else if (items) {
            console.log(items);
            return (<div>

                <h1>Videos</h1>

                <div className="white-bg"> 
	
                    <form id="add_video" onSubmit={this.handleOnSubmit}>

                        <div className="form_item">
                            <input onChange={this.handleOnChange} value={this.state.fields.video_name} type="text" name="video_name" placeholder="Video Title" size="40" />
                        </div>

                        <div className="form_item">
                            <input onChange={this.handleOnChange} value={this.state.fields.video_description} type="text" name="video_description"  placeholder="Video Description" size="40" />
                        </div>

                        <div className="form_item">
                            <div id="datetimepicker" className="input-group input-append date">
                                <input onChange={this.handleOnChange} value={this.state.fields.scheduled_release} name="scheduled_release" type="text" placeholder="Scheduled Release" />
                                <span className="add-on input-group-addon">
                                    <span className="glyphicon glyphicon-calendar"></span>
                                </span>
                                </div>
                                <small>All release times are <em>Eastern Standard Time</em></small>
                        </div>

                        <div className="form_item">
                            <textarea onChange={this.handleOnChange} value={this.state.fields.video_meta} name="video_meta" cols="50" rows="5" placeholder="Video Meta (optional)"></textarea>
                        </div>

                        <div className="form_submit">
                            <h5>Video File</h5>
                            <input onChange={this.handleFileChange} disabled={!isVideoUploadEnabled} accept="video/*" type="file" name="video" />
                        </div>

                        <div className="form_submit">
                            <input disabled={!isFormEnabled} type="submit" name="submit" value="Upload Video" />
                        </div>

                    </form>

                </div>

                { items.length > 0 ? (
                    <div>
                        <h2>Videos</h2>
                        <table className="table table-striped">
                            <thead>
                                <tr>
                                    <th>Video ID</th>
                                    <th>Name</th>
                                    <th>Date</th>
                                    <th>Views</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {items.map(item => (
                                    <tr key={'video_' + item.video_id}>
                                        <td>{item.video_id}</td>
                                        <td>
                                            {item.video_name} <small>({item.video_length} seconds)</small><br />
                                            { (item.ad) ? <small>Selected Ad: {item.ad.video_name}</small> : '' }
                                        </td>
                                        <td><small>{item.date_added}</small></td>
                                        <td>{item.view_count}</td>
                                        <td>
                                            <small>
                                                <a target="_blank" rel="noreferrer" href={item.video_file_url + '.mp4'}>View</a> 
                                                &nbsp;|&nbsp;
                                                <a href={'/edit_video/?id=' + item.video_id}>Edit</a> 
                                                &nbsp;|&nbsp;
                                                <a href="/" onClick={(e) => this.deleteItem(e, item.video_id)}>Delete</a>
                                            </small>
                                        </td>
                                    </tr> 
                                ))}
                            </tbody>
                        </table>
                    </div>
                ) : (
                    <p className="no-item-warning">
                        No existing videos found.
                        <span>Add your first, using the form above.</span>
                    </p>
                )}
        
            </div>);
        } else if (!items) {
            return (
                <div></div>
            );
        }

    }

}