import { Link } from "react-router-dom";
import Nubitt from "../../Base";

export default class ManageHeaderView extends Nubitt {

    constructor(props) {
        super(props);
        this.state = {
            error: null
        };
    }

    render() {
        return(
            <div id="manage-header-view">
                <ul>
                    <li><Link to="/manage/account">Account Settings</Link></li>
                    <li><Link to="/manage/app">App Settings</Link></li>
                    <li><Link to="/manage/contest">Contest Settings</Link></li>
                    <li><Link to="/manage/home_screen">Home Screen Settings</Link></li>
                </ul>
                <hr />
            </div>
        )
    }

}