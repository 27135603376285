import React from 'react';
import Nubitt, { getAPIHeaders, getApiURL, getAssetURL } from '../Base';
import FileUpload from '../components/FileUpload';

export default class Gallery extends Nubitt {

    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLoaded: false,
            items: [],
            showNav: false
        };
        this.deleteGalleryItem = this.deleteGalleryItem.bind(this);
    }

    componentDidMount() {
        document.title = "Gallery : Nubitt Producers";
        this.loadData();
    }

    loadData() {

        fetch(getApiURL() + "/gallery", {
            headers: getAPIHeaders()
        })
        .then(res => res.json())
        .then(
            (result) => {
                this.setState({
                    isLoaded: true,
                    items: result.gallery
                });
            },
            (err) => {
                this.setState({
                    items: [],
                    isLoaded: true,
                    error: err
                });
            }
        )
    }

    handleUploadComplete = (e) => {
        console.log("File has been uploaded");
        console.log(e);
        this.loadData();
    };

    deleteGalleryItem(e, id) {
        e.preventDefault();

        let confirmed = window.confirm("Are you sure you want to delete this gallery image?");
        if (confirmed) {

            let gallery_item_id = id;
            fetch(getApiURL() + "/gallery/" + gallery_item_id, {
                method: 'DELETE',
                headers: getAPIHeaders()
            })
            .then(
                (result) => {
                    this.loadData();
                }
            )

        }

    }

    render() {

        const { error, isLoaded, items } = this.state;

        console.log(items);

        if (error) {
            return <div><br />{error}</div>;
        } else if (!isLoaded) {
            return <div>Loading...</div>;
        } else if (items) {
            return (<div>

                <div>
                    <h2>Gallery Manager</h2>

                    <h3>Add New Gallery Image</h3>
                    <FileUpload
                        uploadUrl={getApiURL() + '/gallery/upload'}
                        onUploadComplete={this.handleUploadComplete}
                    />

                    { items.length > 0 ? (
                        <div>
                            <h3>Gallery Images</h3>
                            <table className="table table-striped">
                                <thead>
                                    <tr>
                                        <th>Images</th>
                                        <th>Manage</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {items.map(item => (
                                        <tr key={'gallery_' + item.id}>
                                            <td><a rel="noreferrer" href={getAssetURL() + item.file_url} target="_blank"><img alt={getAssetURL() + item.file_url} width="200" src={getAssetURL() + item.file_url} /></a></td>
                                            <td><a href="/" onClick={(e) => this.deleteGalleryItem(e, item.id)}><small>Delete Asset</small></a></td>
                                        </tr> 
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    ) : (
                        <p className="no-item-warning">
                            No existing gallery images found.
                            <span>Add your first, using the form above.</span>
                        </p>
                    )}
                </div>
        
            </div>);
        } else if (!items) {
            return (
                <div>No gallery images to show.</div>
            );
        }

    }

}