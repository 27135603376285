import React, { useEffect, useRef } from 'react';
import Pickr from '@simonwep/pickr/dist/pickr.es5.min'; // Import Pickr
import '@simonwep/pickr/dist/themes/classic.min.css';
import '@simonwep/pickr/dist/themes/nano.min.css';

const ColorPicker = ({ defaultColor, onSave }) => {
  const pickrRef = useRef(null);

  useEffect(() => {
    // Create a new Pickr instance when the component mounts
    const pickr = Pickr.create({
      el: pickrRef.current,
      theme: 'nano', // Change this to 'monolith' for a different look
      default: (defaultColor) ? defaultColor : '#2196f3', // Default color
      swatches: [],
      components: {
        // Main components
        preview: true,
        opacity: true,
        hue: true,

        // Input / output Options
        interaction: {
          hex: false,
          rgba: false,
          hsla: false,
          hsva: false,
          cmyk: false,
          input: true,
          clear: false,
          save: true,
        },
      },
    });

    pickr.on('save', (color) => {
        if (typeof onSave === 'function') {
            onSave(color.toHEXA().toString()); // Call the onSave callback with the selected color
        }
        console.log('Selected Color:', color.toHEXA().toString());
        pickr.hide();
    });

    // Clean up the Pickr instance when the component unmounts
    return () => {
      pickr.destroyAndRemove();
    };
  }, [defaultColor, onSave]);

  return <div ref={pickrRef}></div>;
};

export default ColorPicker;