import { Component } from 'react'
export default class Nubitt extends Component {

}

export function getAPIHeaders(multipart=false) {
    if (multipart) {
        return {
            'Authorization': 'Bearer ' + user().token,
            'Client': 'producer-ui'
        }        
    } else {
        return {
            'Authorization': 'Bearer ' + user().token,
            'Client': 'producer-ui',
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }   
    }
}

export function isUserLoggedIn() {
    let userData = localStorage.getItem("user_data");
    let token = localStorage.getItem("user_token");
    if (userData) {
        let user = JSON.parse(userData);
        if (user.login_user && token) {
            return true;
        }
    }
    return false;
}

export function user() {
    let userData = localStorage.getItem("user_data");
    if (userData) {
        let user = JSON.parse(userData);
        user.token = localStorage.getItem("user_token");
        return user;
    }
    return {login_user: ''};
}

export function getCurrentUserToken() {
    return '';
}

export function getApiURL() {
    if (window.document.location.href.indexOf('producer.nubitt.com') !== -1) {
        return 'https://api.nubitt.com/api/producer';
    } else {
        return 'http://0.0.0.0:3000/api/producer';
    }
}

export function getAssetURL() {
    if (window.document.location.href.indexOf('producer.nubitt.com') !== -1) {
        return 'https://api.nubitt.com/public';
    } else {
        return 'http://0.0.0.0:3000';
    }
}

export function formatPhoneNumber(phoneNumber) {

    if (!phoneNumber) return phoneNumber;

    // Remove all non-digit characters from the input string
    const digitsOnly = phoneNumber.replace(/\D/g, '');
  
    // Check if the input is a valid phone number (10 digits)
    if (digitsOnly.length === 10) {
      // Format the phone number as (XXX) XXX-XXXX
      return `(${digitsOnly.slice(0, 3)}) ${digitsOnly.slice(3, 6)}-${digitsOnly.slice(6)}`;
    } else {
      // If the input doesn't have 10 digits, return it as is
      return phoneNumber;
    }

  }