import Nubitt, { getAPIHeaders, getApiURL } from "../../Base";
import ManageHeaderView from "./ManageHeaderView";
import DraggableRow from '../../components/DraggableRow';
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

export default class ManageHomeScreen extends Nubitt {

    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLoaded: false,
            items: [],
            self: [],
            videos: [],
            pdfs: [],
            images: [],
            showNav: false,
            buttonSelectMenu: [],
            showLinkBox: false,
            buttonType: [
                {label: 'Choose Type...', value: '-1'},
                {label: 'Image (png or jpg)', value: 'image'},
                {label: 'PDF', value: 'pdf'},
                {label: 'Video (Single)', value: 'video'},
                {label: 'Video (Bucket)', value: 'videos'},
                {label: 'In-app Link', value: 'self'},
                {label: 'Website URL', value: '_blank'}
            ],
            newButton: {
                btn_title: '',
                btn_visible: '1',
                btn_type: '',
                btn_type_value: '',
                freetext_btn_url: ''
            }
        };
        this.handleOnChange = this.handleOnChange.bind(this); 
        this.handleOnSubmit = this.handleOnSubmit.bind(this);
    }

    componentDidMount() {
        document.title = "Manage HomeScreen : Nubitt Producers";
        this.loadData();
    }

    loadData() {

        fetch(getApiURL() + "/manage/home_screen", {
            headers: getAPIHeaders()
        })
        .then(res => res.json())
        .then(
            (result) => {
                this.setState({
                    isLoaded: true,
                    items: result.items,
                    self: result.self
                });

                var images = [{label: 'Choose Image...', value: '-1'}]
                result.images.map(item => (
                    images.push({label: item.file_name, value: item.file_url})
                ))

                var pdfs = [{label: 'Choose PDF...', value: '-1'}]
                result.pdfs.map(item => (
                    pdfs.push({label: item.file_name, value: item.file_url})
                ))

                var videos = [{label: 'Choose Video...', value: '-1'}]
                result.videos.map(item => (
                    videos.push({label: item.video_name, value: item.video_id})
                ))

                this.setState({
                    videos: videos,
                    pdfs: pdfs,
                    images: images
                });

            },
            (err) => {
                this.setState({
                    items: [],
                    isLoaded: true,
                    error: err
                });
            }
        )
    }

    deleteItem(e, id) {
        e.preventDefault();

        let confirmed = window.confirm("Are you sure you want to delete this button?");
        if (confirmed) {

            fetch(getApiURL() + "/manage/home_screen/button/" + id, {
                method: 'DELETE',
                headers: getAPIHeaders()
            })
            .then(
                (result) => {
                    this.loadData();
                }
            )

        }

    }

    handleOnChange(e) {
        //console.log(e.target.name + " : " + e.target.value);
        const stateData = this.state;
        stateData.newButton[e.target.name] = e.target.value
        this.setState({stateData});

        if (e.target.name === 'btn_type') {
            this.setState({showLinkBox: false});
            if (e.target.value === 'image') {
                this.setState(state => {
                    state.buttonSelectMenu = this.state.images
                    state.newButton.btn_type_value = ''
                    return state
                })
            } else if (e.target.value === 'pdf') {
                this.setState(state => {
                    state.buttonSelectMenu = this.state.pdfs
                    state.newButton.btn_type_value = ''
                    return state
                })
            } else if (e.target.value === 'video') {
                this.setState(state => {
                    state.buttonSelectMenu = this.state.videos
                    state.newButton.btn_type_value = ''
                    return state
                })
            }  else if (e.target.value === 'self') {
                this.setState(state => {
                    state.buttonSelectMenu = this.state.self
                    state.newButton.btn_type_value = ''
                    return state
                })
            }  else if (['_blank', '-1', 'videos'].includes(e.target.value)) {
                this.setState(state => {
                    state.buttonSelectMenu = []
                    state.newButton.btn_type_value = ''
                    return state
                })
                if (e.target.value === '_blank') {
                    this.setState({showLinkBox: true});
                }
            }
        }

    }

    handleOnSubmit(e) {
        e.preventDefault();

        if (this.state.newButton.btn_title === '') {
            alert("Button label is required");
            return
        }

        fetch(getApiURL() + "/manage/home_screen/add_button", {
            method: 'POST',
            headers: getAPIHeaders(),
            body: JSON.stringify({
                fields: this.state.newButton
            })
        })
        .then(
            (result) => {
                console.log("Button response:");
                console.log(result);
                //this.clearAllFields();
                //this.loadData();
                window.location.reload();
            }
        )

    }

    moveRow = (dragIndex, hoverIndex) => {
        const { items } = this.state;
        const draggedItem = items[dragIndex];

        const updatedItems = [...items];
        updatedItems.splice(dragIndex, 1);
        updatedItems.splice(hoverIndex, 0, draggedItem);

        this.setState({ items: updatedItems });
    };

    handleDrop = () => {
        const { items } = this.state;
        this.saveOrderToBackend(items);
    };

    saveOrderToBackend = (newOrder) => {

        console.log(newOrder);

        fetch(getApiURL() + "/manage/home_screen/update", {
            method: 'POST',
            headers: getAPIHeaders(),
            body: JSON.stringify({ newOrder }),
        })
        .then(response => response.json())
        .then(data => {
            console.log('Home buttons updated successfully:', data);
        })
        .catch(error => {
            console.error('Error saving Home Buttons:', error);
        });
    };

    render() {

        const { error, isLoaded, items, buttonType, buttonSelectMenu, showLinkBox } = this.state;

        let home_buttons_list = ''
        let home_button_form = (
            <div>
                <h3>Existing Home Buttons</h3>
                <form id="add_new_button_form" onSubmit={this.handleOnSubmit}>
                        
                    <p>
                        <span style={{display: 'inline-block', width: '120px'}}>Button Title</span>
                        <input
                            onChange={this.handleOnChange}
                            value={this.state.newButton.btn_title}
                            size="50" 
                            type="text" 
                            name="btn_title" />
                    </p>

                    <p>
                        <span style={{display: 'inline-block', width: '120px'}}>Button Visible</span>
                        <select 
                        onChange={this.handleOnChange} 
                        value={this.state.newButton.btn_visible}
                        style={{padding:'10px'}} 
                        name="btn_visible">
                            <option value="1">Visible</option>
                            <option value="0">Hidden</option>
                        </select>
                    </p>

                    <p>
                        <span style={{display: 'inline-block', width: '120px'}}>Button Type</span>
                        <select 
                        onChange={this.handleOnChange}
                        value={this.state.newButton.btn_type}
                        style={{padding: '10px'}} 
                        name="btn_type">
                            {buttonType.map(item => (
                                <option key={'option_' + item.value} value={item.value}>{item.label}</option>
                            ))}
                        </select>
                    </p>

                    <p style={{display: (buttonSelectMenu.length > 0) ? 'inline-block' : 'none'}}>
                        <span style={{display: 'inline-block', width: '120px'}}>Destination</span>
                        <select 
                        onChange={this.handleOnChange}
                        value={this.state.newButton.btn_type_value}
                        style={{padding: '10px'}} 
                        name="btn_type_value">
                            {buttonSelectMenu.map(item => (
                                <option key={'buttonSelectMenu_' + item.value} value={item.value}>{item.label}</option>
                            ))}
                        </select>
                    </p>

                    <p style={{display: (showLinkBox) ? 'inline-block' : 'none'}}>
                        <span style={{display: 'inline-block', width: '120px'}}>Dest. URL</span>
                        <input 
                            onChange={this.handleOnChange}
                            value={this.state.newButton.freetext_btn_url}
                            name="freetext_btn_url" 
                            type="text" 
                            size="50" />
                    </p>

                    <p>
                        <input name="submit" type="submit" value="Add New Button" />
                        {/* &nbsp;<a href="/">Cancel</a> */}
                    </p>

                </form>
            </div>
        )

        if (error) {
            return <div><br />{error}</div>;
        } else if (!isLoaded) {
            return <div>Loading...</div>;
        } else if (items) {

            //console.log(items);

            home_buttons_list = (
                <div>
                    <table id="btn_list_table" className="table table-striped">
                        <thead>
                        <tr>
                            <th>Title</th>
                            <th>Type</th>
                            <th>URL</th>
                            <th>Visibility</th>
                            <th style={{width:"120px"}}>Action</th>
                        </tr>
                        </thead>

                        <tbody>
                            {items.map((item, index) => (
                                <DraggableRow key={item.id} item={item} index={index} moveRow={this.moveRow} onDrop={this.handleDrop} />
                            ))}
                        </tbody>
                    </table>
                </div>
            );

            if (items.length === 0) {
                home_buttons_list = (
                    <p className="no-item-warning">
                        No existing Home Screen buttons found.
                        <span>Add your first, using the form above.</span>
                    </p>
                )
            }

            return (
                <DndProvider backend={HTML5Backend}>
                    <div key="home_buttons-table">
                        {<ManageHeaderView />}
                        <h2>Home Screen Settings</h2>
                        <h3>Add New Button</h3>
                        {home_button_form}
                        {home_buttons_list}
                    </div>
                </DndProvider>
            )

        } else if (!items || items.length === 0) {
            return (
                <div key="home_buttons-table">
                    {<ManageHeaderView />}
                    <h1>Home Screen Settings</h1>
                    <div>No data found...</div>
                </div>
            )
        }

    }

}