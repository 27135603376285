import Nubitt, { getAPIHeaders, getApiURL } from "../../Base";
import ManageHeaderView from "./ManageHeaderView";

export default class ManageAccount extends Nubitt {

    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLoaded: false,
            account: null,
            showNav: false  
        };
    }

    componentDidMount() {
        document.title = "Manage Account : Nubitt Producers";
        this.loadData();
    }

    loadData() {

        fetch(getApiURL() + "/manage/account", {
            headers: getAPIHeaders()
        })
        .then(res => res.json())
        .then(
            (result) => {
                this.setState({
                    isLoaded: true,
                    account: result.account
                });
            },
            (err) => {
                this.setState({
                    items: [],
                    isLoaded: true,
                    error: err
                });
            }
        )
    }

    render() {

        const { error, isLoaded, account } = this.state;
        
        if (error) {
            return <div><br />{error}</div>;
        } else if (!isLoaded) {
            return <div>Loading...</div>;
        } else if (account) {
            return(
                <div>
    
                    <ManageHeaderView />
    
                    <h2>Account Settings</h2>
                    
                    <form action="" method="post">
                        
                        <p>
                            <span style={{display: 'inline-block', width: '100px'}}>Username</span>
                            <input 
                                size="50" 
                                type="text" 
                                name="login_user" 
                                style={{marginLeft: -2 + 'px', color: '#999999'}}
                                disabled="disabled"
                                placeholder={account.login_user} />
                        </p>

                        <p>
                            <span style={{display: 'inline-block', width: '100px'}}>App Key</span>
                            <input 
                                size="50" 
                                type="text" 
                                name="app_key" 
                                style={{marginLeft: -2 + 'px', color: '#999999'}}
                                disabled="disabled"
                                placeholder={account.app_key} />
                        </p>
    
                        <p>
                            <span style={{display: 'inline-block', width: '100px'}}>Email</span>
                            <input 
                                size="50" 
                                type="text" 
                                name="email" 
                                style={{marginLeft: -2  + 'px'}}
                                disabled="disabled"
                                placeholder={account.email} />
                        </p>
                        
                        <input type="hidden" name="app_id" value="23" />
    
                    </form>
                </div>
            )
        }

    }

}