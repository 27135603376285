import Nubitt, { getAPIHeaders, getApiURL } from '../Base';

export default class Locations extends Nubitt {

    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLoaded: false,
            items: [],
            showNav: false,
            fields: {
                location_name: '',
                location_phone: '',
                location_address: '',
                location_hours: '',
            }
        };
        this.handleOnChange = this.handleOnChange.bind(this); 
        this.handleOnSubmit = this.handleOnSubmit.bind(this);
    }

    componentDidMount() {
        document.title = "Locations : Nubitt Producers";
        this.loadData();
    }

    loadData() {

        fetch(getApiURL() + "/locations", {
            headers: getAPIHeaders()
        })
        .then(res => res.json())
        .then(
            (result) => {
                this.setState({
                    isLoaded: true,
                    items: result.locations
                });
            },
            (err) => {
                this.setState({
                    items: [],
                    isLoaded: true,
                    error: err
                });
            }
        )
    }

    clearAllFields() {
        for (let field in this.state.fields) {
            this.state.fields[field] = '';
        }  
    }

    checkFields() {
        for (let field in this.state.fields) {
            if (this.state.fields[field].length === 0) {
                return false
            }
        }
        return true
    }

    handleOnChange(e) {
        const stateData = this.state;
        stateData.fields[e.target.name] = e.target.value
        this.setState({stateData});
    }

    handleOnSubmit(e) {
        e.preventDefault();  
        console.log(e);
        console.log(this.state.fields);

        fetch(getApiURL() + "/locations/add", {
            method: 'POST',
            headers: getAPIHeaders(),
            body: JSON.stringify({
                fields: this.state.fields
            })
        })
        .then(
            (result) => {
                console.log(result);
                //this.clearAllFields();
                this.loadData();
            }
        )

    }

    deleteItem(e, id) {
        e.preventDefault();

        let confirmed = window.confirm("Are you sure you want to delete this location entry?");
        if (confirmed) {

            fetch(getApiURL() + "/location/" + id, {
                method: 'DELETE',
                headers: getAPIHeaders()
            })
            .then(
                (result) => {
                    this.loadData();
                }
            )

        }

    }

    render() {

        const { error, isLoaded, items } = this.state;
        const isFormEnabled = this.checkFields();

        console.log(items);

        if (error) {
            return <div><br />{error}</div>;
        } else if (!isLoaded) {
            return <div>Loading...</div>;
        } else if (items) {
            return (<div>

                <div className="white-bg"> 
                    
                    <h2>Add Location</h2>

                    <form id="add_location" onSubmit={this.handleOnSubmit}>

                        <div className="form_item">
                            <input onChange={this.handleOnChange} value={this.state.fields.location_name} type="text" name="location_name" id="location_name" placeholder="Location Name" size="40" />
                        </div>

                        <div className="form_item">
                            <input onChange={this.handleOnChange} value={this.state.fields.location_phone} type="text" name="location_phone" id="location_phone" placeholder="Location Phone" size="40" />
                        </div>

                        <div className="form_item">
                            <input onChange={this.handleOnChange} value={this.state.fields.location_address} type="text" name="location_address" id="location_address" placeholder="Location Address" size="40" />
                        </div>

                        <div className="form_item">
                            <input onChange={this.handleOnChange} value={this.state.fields.location_hours} type="text" name="location_hours" id="location_hours" placeholder="Location Hours" size="40" />
                        </div>

                        <div className="form_submit">
                            <input id="add_new_location_btn" disabled={!isFormEnabled} type="submit" name="submit" value="Add New Location" />
                        </div>

                    </form>

                </div>

                { items.length > 0 ? (
                    <div>
                        <h2>Locations</h2>
                        <table className="table table-striped">
                            <thead>
                                <tr>
                                    <th>Location</th>
                                    <th>Address</th>
                                    <th>Phone</th>
                                    <th>Hours</th>
                                    <th>Action</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {items.map(item => (
                                    <tr key={'location_' + item.id}>
                                        <td>{item.location_name}</td>
                                        <td>{item.location_address}</td>
                                        <td>{item.location_phone}</td>
                                        <td>{item.location_hours}</td>
                                        <td><a href="/" onClick={(e) => this.deleteItem(e, item.id)}><small>Delete</small></a></td>
                                    </tr> 
                                ))}
                            </tbody>
                        </table>
                    </div>
                ) : (
                    <p className="no-item-warning">
                        No existing locations found.
                        <span>Add your first, using the form above.</span>
                    </p>
                )}
        
            </div>);
        } else if (!items) {
            return (
                <div>No locations to show.</div>
            );
        }

    }

}