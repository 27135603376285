import Nubitt, { getAPIHeaders, getApiURL } from "../../Base";
import ManageHeaderView from "./ManageHeaderView";

export default class ManageContest extends Nubitt {

    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLoaded: false,
            items: [],
            showNav: false,
            selectedContestImage: '',
            selectedContestTerms: ''
        };
    }

    componentDidMount() {
        document.title = "Manage Contest : Nubitt Producers";
        this.loadData();
    }

    loadData() {

        fetch(getApiURL() + "/manage/contest", {
            headers: getAPIHeaders()
        })
        .then(res => res.json())
        .then(
            (result) => {
                this.setState({
                    isLoaded: true,
                    items: result.items
                });
                this.setState({
                    selectedContestImage: result.items.account.contest_image,
                    selectedContestTerms: result.items.account.contest_terms_url
                });
            },
            (err) => {
                this.setState({
                    items: [],
                    isLoaded: true,
                    error: err
                });
            }
        )
    }

    handleSelectedContestImageChange = (e) => {
        this.setState({ selectedContestImage: e.target.value });
    };

    handleSelectedContestTermsChange = (e) => {
        this.setState({ selectedContestTerms: e.target.value });
    };

    updateContest = (e) => {
        console.log('updateContest');
        console.log(this.state.selectedContestImage);
        console.log(this.state.selectedContestTerms);

        fetch(getApiURL() + "/manage/contest", {
            method: 'POST',
            headers: getAPIHeaders(),
            body: JSON.stringify({
                contest_image: this.state.selectedContestImage,
                contest_terms_url: this.state.selectedContestTerms
            })
        })
        .then(
            (result) => {
                console.log(result);
                //this.loadData();
            }
        )

    };

    render() {

        const { error, isLoaded, items, selectedContestImage, selectedContestTerms } = this.state;
        console.log(items);

        if (error) {
            return <div><br />{error}</div>;
        } else if (!isLoaded) {
            return <div>Loading...</div>;
        } else if (items) {
            return(
                <div>
    
                    <ManageHeaderView />
    
                    <h2>Contest Settings</h2>
    
                    <p>
                        <span style={{display: 'inline-block', width: '150px'}}>Contest Image</span>
                        <select value={selectedContestImage} onChange={this.handleSelectedContestImageChange} style={{marginLeft: -2  + 'px', padding: 10 + 'px'}} name="contest_image">
                            <option key="item_image_-1" defaultValue="-1"></option>
                            {items.images.map(item => (
                                <option key={'image_' + item.id} value={item.file_url}>{item.file_name}</option>
                            ))}
                        </select>
                    </p>

                    <p>
                    <span style={{display: 'inline-block', width: '150px'}}>Contest Terms</span>
                        <select value={selectedContestTerms} onChange={this.handleSelectedContestTermsChange} style={{marginLeft: -2  + 'px', padding: 10 + 'px'}} name="contest_terms_url">
                        <option key="item_pdf_-1" defaultValue="-1"></option>
                            {items.pdfs.map(item => (
                                <option key={'pdf_' + item.id} value={item.file_url}>{item.file_name}</option>
                            ))}
                        </select>
                    </p>

                    <p>
                        <input onClick={this.updateContest} name="submit" type="submit" value="Update Contest Settings" /> 
                    </p>
    
                </div>
            )
        }

    }

}