import Nubitt, { getAPIHeaders, getApiURL } from "../Base";

export default class SendMessageView extends Nubitt {

    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLoaded: false,
            items: [],
            showNav: false,
            messageType: '',
            messageVideo: '',
            messageText: '',
            messageCoupon: '',
            showVideoList: false,
            showCouponList: false,
            userList: [],
            videoList: [],
            couponList: [],
            userTags: [],
            signupMetadata: [],
            checkedItems: {}
        };
        this.handleOnChange = this.handleOnChange.bind(this); 
        this.handleOnSubmit = this.handleOnSubmit.bind(this);
        this.handleTagCheckboxChange = this.handleTagCheckboxChange.bind(this);

        this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
		this.checkSpecificCheckboxes = this.checkSpecificCheckboxes.bind(this);
		this.uncheckAllCheckboxes = this.uncheckAllCheckboxes.bind(this);
		this.checkAllCheckboxes = this.checkAllCheckboxes.bind(this);

    }

    handleOnChange(e) {
        console.log(e.target.name + " : " + e.target.value);
        const stateData = this.state;
        stateData[e.target.name] = e.target.value

        if (e.target.name === 'messageType') {
            stateData['showVideoList'] = false;
            stateData['showCouponList'] = false;
            if (e.target.value === 'video') {
                stateData['showVideoList'] = true;
            } else if (e.target.value === 'text') {

            } else if (e.target.value === 'coupon') {
                stateData['showCouponList'] = true;
            }
        }

        this.setState({stateData});
    }

    handleOnSubmit(e) {
        e.preventDefault();

        let usersToSend = this.getSelectedUsers();

        console.log("messageType: " + this.state.messageType);
        console.log("messageCoupon: " + this.state.messageCoupon);
        console.log("messageText: " + this.state.messageText);
        console.log("messageVideo: " + this.state.messageVideo);
        console.log("usersToSend: " + usersToSend);

        if (!this.state.messageType) {
            alert("Please select a message type.");
            return;
        }

        if (this.state.messageType === 'video' && this.state.messageVideo === '') {
            alert("Please select a video to send.");
            return;
        }

        if (this.state.messageType === 'coupon' && this.state.messageCoupon === '') {
            alert("Please select a coupon to send.");
            return;
        }

        if (this.state.messageType === 'coupon' && this.state.messageText === '') {
            alert("Please provide a message to send a coupon.");
            return;
        }

        if (this.state.messageType === 'text' && this.state.messageText === '') {
            alert("Please provide a message to send.");
            return;
        }

        if (usersToSend.length === 0) {
            alert("Please select users to send message to.");
            return;
        }

        let string = "Sending notification to the following user(s):\n";
        string = string + "Users: " + usersToSend.join(', ') + "\n";

        if (this.state.messageType === 'coupon' && this.state.messageCoupon !== '') {
            string = string + "Send Coupon: " + this.state.messageCoupon + "\n";
            this.state.messageText = this.state.messageText.replace(/{coupon}/gi, this.state.messageCoupon);
        }
        if (this.state.messageType === 'video' && this.state.messageVideo !== '') {
            string = string + "Send Video with ID: " + this.state.messageVideo + "\n";
        }

        string = string + "With Message: " + this.state.messageText + "\n";

        alert(string);

        fetch(getApiURL() + "/send_message", {
            method: 'POST',
            headers: getAPIHeaders(),
            body: JSON.stringify({
                message_type: this.state.messageType,
                coupon: this.state.messageCoupon,
                text: this.state.messageText,
                video_id: this.state.messageVideo,
                users_to_send: usersToSend
            })
        })
        .then(
            (result) => {
                console.log("Response:");
                console.log(result);
                window.location.reload();
            }
        )

    }

    componentDidMount() {
        document.title = "Send Message : Nubitt Producers";
        this.loadData();
    }

    loadData() {

        fetch(getApiURL() + "/send_message", {
            headers: getAPIHeaders()
        })
        .then(res => res.json())
        .then(
            (result) => {
                console.log(result);
                this.setState({
                    isLoaded: true,
                    videoList: result.videos,
                    couponList: result.coupons,
                    userList: result.users,
                    userTags: result.tags,
                    signupMetadata: result.signupMetadata
                });
            },
            (err) => {
                this.setState({
                    items: [],
                    isLoaded: true,
                    error: err
                });
            }
        )
    }

    handleTagCheckboxChange(e) {
        console.log(this.state.signupMetadata);
        if (e.target.checked === true) {
            this.checkSpecificCheckboxes(this.state.signupMetadata[e.target.value]);
        } else {
            this.uncheckSpecificCheckboxes(this.state.signupMetadata[e.target.value]);
        }
    };

    getUsersToSend() {
        return this.state.userList
    }

    //
    // Checkbox handlers
    //

    getSelectedUsers() {
	    const selectedUsers = Object.keys(this.state.checkedItems).filter(
		    (key) => this.state.checkedItems[key]
		);
		return selectedUsers;
	}

    handleCheckboxChange(e) {
		const { value, checked } = e.target;
		this.setState((prevState) => ({
			checkedItems: {
				...prevState.checkedItems,
				[value]: checked,
			},
	    }));
	}

    setCheckedItems(items) {
        console.log("Checked Items: ");
        console.log(items);
        this.setState({ checkedItems: items });
    }

    checkSpecificCheckboxes(userIds) {
        if (!userIds) return;
		const updatedCheckedItems = { ...this.state.checkedItems };
		userIds.forEach((id) => {
			updatedCheckedItems[id] = true;
		});
		this.setState({
			checkedItems: updatedCheckedItems,
		});
	}

    uncheckSpecificCheckboxes(userIds) {
        if (!userIds) return;
		const updatedCheckedItems = { ...this.state.checkedItems };
		userIds.forEach((id) => {
			updatedCheckedItems[id] = false;
		});
		this.setState({
			checkedItems: updatedCheckedItems,
		});
	}

    checkAllCheckboxes() {
        this.checkSpecificCheckboxes(
            this.state.userList.map(item => item.user_id)
        );
	}

    uncheckAllCheckboxes() {
		this.setState({ checkedItems: {} });
	}

    render() {
        const { error, isLoaded, items, showVideoList, showCouponList, videoList, couponList, userTags } = this.state;

        if (error) {
            return <div><br />{error}</div>;
        } else if (!isLoaded) {
            return <div>Loading...</div>;
        } else if (items) {
            return(
                <div>
    
                    <h2>Send Message</h2>
    
                    <form method="post" onSubmit={this.handleOnSubmit}>
        
                        <hr />
        
                        <p>
                            <span style={{display: 'inline-block', width: '120px'}} className="lbl">Message Type:&nbsp;</span>
                            <select name="messageType" onChange={this.handleOnChange} value={this.state.messageType}>
                                <option value="-1">Choose Type...</option>
                                <option value="video">Video</option>
                                <option value="coupon">Coupon</option>
                                <option value="text">Text</option>
                            </select>
                        </p>
        
                        <p style={{display: (showVideoList) ? 'inline-block' : 'none'}}>
                            <span style={{display: 'inline-block', width: '120px'}} className="lbl">Video:</span>
                            <select name="messageVideo" onChange={this.handleOnChange} value={this.state.messageVideo}>
                                <option defaultValue="-1">Choose a Video...</option>
                                {videoList.map(item => (
                                    <option key={'video_option_' + item.video_id} value={item.video_id}>{item.video_name}</option>
                                ))}
                            </select>
                        </p>
    
                        <p style={{display: (showCouponList) ? 'inline-block' : 'none'}}>
                            <span style={{display: 'inline-block', width: '120px'}} className="lbl">Coupon:</span>
                            <select name="messageCoupon" onChange={this.handleOnChange} value={this.state.messageCoupon}>
                                <option defaultValue="-1">Choose a Coupon...</option>
                                {couponList.map(item => (
                                    <option key={'coupon_option_' + item.id} value={item.code}>{item.code}</option>
                                ))}
                            </select>
                        </p>
        
                        <div>
                            <p>
                                <span className="lbl">Message:</span>
                            </p>
                            <textarea style={{padding: '10px'}} name="messageText" rows="5" cols="50" onChange={this.handleOnChange} value={this.state.messageText}></textarea><br />
                            <small>Message is required for <strong>text</strong> Message Type option.<br />Use <strong>{"{coupon}"}</strong> to inject into message.</small>
                        </div>
        
                        <hr />
                        <h4>2. Send User Options</h4>
                        <hr />
    
                        <strong>a) Send to tagged users</strong>
        
                        <table className="table table-striped">
                            <tbody> 
                                {userTags.map(item => (
                                <tr key={"tag" + item.id}>
                                    <td>
                                        <input 
                                            onChange={this.handleTagCheckboxChange}
                                            className="user_checkbox" 
                                            type="checkbox"
                                            id={"tag_" + item.id}
                                            name={"tag_" + item.id}
                                            value={item.id} />
                                        &nbsp;{item.qr_tag}
                                    </td>
                                </tr>      
                                ))}      
                            </tbody>
                        </table>
    
                        <hr />
        
                        <strong>b) Choose Users To Message</strong>
                    
                        <p>
                            Select: <a href="#check_all" onClick={this.checkAllCheckboxes}>All</a> | <a href="#uncheck_all" onClick={this.uncheckAllCheckboxes}>None</a>
                            </p>

                        <p>
                            <input id="submit_btn" name="submit" type="submit" value="Send Message" />
                        </p>

                        <table className="table table-striped">
                            <thead>
                                <tr><th>&nbsp;</th>
                                <th>User ID</th>
                                <th>Device ID</th>
                                <th>Email</th>
                                <th>Sign Up Date</th>
                            </tr></thead>
                            <tbody>
                                {this.getUsersToSend().map(item => (
                                    <tr key={'users_' + item.user_id}>
                                        <td><input 
                                            onChange={this.handleCheckboxChange} 
                                            className="user_checkbox" 
                                            type="checkbox" 
                                            value={item.user_id} 
                                            checked={this.state.checkedItems[item.user_id] || false}
                                        /></td>
                                        <td>{item.user_id}</td>
                                        <td>{item.device_id}</td>
                                        <td>{item.email}</td>
                                        <td>{item.formatted_created}</td>
                                </tr>
                                ))}
                            </tbody>
                        </table>
        
                        <span className="key"><strong>CAUTION:</strong> These messages are sent to your app users.</span>
        
                        <p>
                            <input id="submit_btn" name="submit" type="submit" value="Send Message" />
                            {/* style={{backgroundColor: 'rgb(204, 204, 204)', borderColor: 'rgb(204, 204, 204)'}} */}
                        </p>
            
                    </form>
    
                </div>
            )
        }

    }

}